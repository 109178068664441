<template>
  <div>
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <v-row>
      <v-col cols="12" sm="4">
        <div class="title">
          <h1>{{ examData.class_name }}</h1>
        </div>
      </v-col>
      <v-col cols="6" sm="4">
        <label class="search" for="inpt_search">
          <input
            id="inpt_search"
            @focus="inputFocus"
            @blur="inputBlur"
            type="text"
            v-model="search"
          />
        </label>
      </v-col>
      <v-col cols="6" sm="4">
        <v-icon @click="printExamData" :title="$t('Print')">print</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="title">
          <h2 class="main-color">{{ examData.exam_name }} |</h2>
        </div>
      </v-col>
      <v-col cols="12" md="10" class="info-list">
        <ul class="inline">
          <li>
            <strong> {{ $t("Date") }}: </strong>{{ examData.exam_date }}
          </li>
          <li>
            <strong> {{ $t("Qualifier") }}:</strong> {{ examData.qualifier }}
          </li>
          <li>
            <strong> {{ $t("Subject") }}:</strong> {{ examData.subject_name }}
          </li>
          <li>
            <strong> {{ $t("Duration") }}:</strong>
            {{ examData.duration_hour }} .
            {{ examData.duration_min }}
          </li>
          <li>
            <strong> {{ $t("Marks") }}:</strong> {{ examData.exam_mark }}
          </li>
          <li>
            <strong> {{ $t("Academic Period") }}:</strong>
            {{ examData.academicYear }}
          </li>
        </ul>
      </v-col>
      <v-col cols="12" md="12" class="hr-div">
        <hr />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :options.sync="options"
          :headers="headers"
          :items="items"
          class="elevation-1 level1 ExamMarks"
          :loading="loading"
          item-key="id"
          loading-text="Loading... Please wait"
          hide-default-footer
          :search="search"
        ></v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      items: [],
      headers: [],
      classId: "",
      examId: "",
      examData: [],
      search: "",
    };
  },
  methods: {
    inputFocus() {
      $("#inpt_search")
        .parent("label")
        .addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search")
          .parent("label")
          .removeClass("active");
      }
    },
    getData() {
      axios
        .get(
          this.getApiUrl +
            "/onlineexams/resultStatistics/" +
            this.classId +
            "/" +
            this.examId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.headers = response.data.data.headers;
          this.items = response.data.data.items;
          this.examData = response.data.data.examData;
        });
    },
    printExamData() {
      axios
        .get(
          this.getApiUrl +
            "/onlineexams/printPdfExamResultStatistics/" +
            this.classId +
            "/" +
            this.examId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            window.open(response.data.data);
            // this.close();
          }
        });
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
      );
    },
  },
  mounted() {
    this.classId = this.$router.currentRoute.params.classId;
    this.examId = this.$router.currentRoute.params.examId;
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
.title h2 {
  margin-bottom: 0 !important;
  font-size: 1rem;
}
.info-list {
  //   padding-top: 0;
  //   padding-bottom: 0;
  li {
    display: inline-block;
    width: 15%;
    font-size: 11px;
    // padding-right: 1px;
  }
}

@media (max-width: 600px) {
  .info-list {
    li {
      font-size: 16px;
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.icons-div,
.hr-div {
  padding-top: 0;
  padding-bottom: 0;

  hr {
    margin-bottom: 0 !important;
    margin-top: 0.5rem !important;
  }
}

.table-grid {
  padding-top: 0 !important;
}

button.ui-btn.submit:disabled {
  cursor: not-allowed;
}
// start search input
// .search {
//   display: inline-block;
//   position: relative;
//   height: 30px;
//   width: 30px;
//   box-sizing: border-box;
//   margin: 0px 8px 7px 0px;
//   padding: 7px 9px 0px 9px;
//   border: 2px solid #bbbbbb;
//   border-radius: 25px;
//   transition: all 200ms ease;
//   cursor: text;
//   padding: 3px 5px;

//   &:after {
//     content: "";
//     position: absolute;
//     width: 2px;
//     height: 15px;
//     right: -5px;
//     top: 21px;
//     background: #bbbbbb;
//     border-radius: 3px;
//     transform: rotate(-45deg);
//     transition: all 200ms ease;
//   }

//   &.active,
//   &:hover {
//     width: 200px;
//     margin-right: 0px;

//     &:after {
//       height: 0px;
//     }
//   }

//   input {
//     width: 100%;
//     border: none;
//     box-sizing: border-box;
//     font-family: Helvetica;
//     font-size: 15px;
//     color: inherit;
//     background: transparent;
//     outline-width: 0px;
//   }
// }
</style>
